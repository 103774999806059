<template>
<v-container fluid>
  <v-row class="d-flex justify-space-between">
    <h2>Artist Commission</h2>
    <span>
    <v-btn
      class="ma-2"
      color="primary"
      @click="download"
      :loading="loadingPdf"
    >
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
    <v-btn
      class="ma-2"
      color="primary"
      @click="downloadExcel"
    >
      <v-icon>mdi-table</v-icon>
    </v-btn>
    <v-btn
      class="ma-2"
      color="primary"
      @click="downloadXeroExcel"
    >Raw
      <v-icon>mdi-table</v-icon>
    </v-btn>
    </span>
  </v-row>
  <date-range @getDates="getCommission"></date-range>
  <v-alert v-if="!Object.keys(artists).length && loaded" type="info">
    <p>No data to display.</p>
  </v-alert>
  <v-card flat>
    <v-card-text>
  <h3>Total £{{totalCommission.toFixed(2)}}</h3>
    </v-card-text>
  </v-card>
  <v-expansion-panels v-if="Object.keys(artists).length" accordion v-model="panel">
    <v-expansion-panel v-for="(artist, index) of artists" :key="index">
      <v-expansion-panel-header>
        <v-row>
          <v-btn
            class="ma-2"
            color="primary"
            @click.stop="downloadArtistCommission(artist[0].artistCode,index)"
            :loading="loadingArtistPdf === index"
          >
          <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          <v-col>
            <h4>{{artist[0].artist}} (£{{getArtistSummary(artist).toFixed(2)}})</h4>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="detailHeaders"
          :items="artist"
          class="elevation-1 my-2 mr-4"
          hide-default-footer
          disable-pagination
          dense
        >
          <!-- <template v-slot:[`item.category`]="{ item }">
            {{item.category}} @ £{{item.unitPrice.toFixed(2)}}
          </template> -->
          <template v-slot:[`item.commission`]="{ item }">
            £{{item.commission.toFixed(2)}}

          </template>
          <template slot="body.append">
            <tr>
                <td>Total</td>
                <th></th>
                <th></th>
                <td style="text-align:right">£{{getArtistSummary(artist).toFixed(2)}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-container>
</template>

<script>
import ReportApi from '@/api/admin/report'
import DateRange from '@/components/DateRange'
import xlsx from 'xlsx'
import _ from 'lodash'

export default {
  components: { DateRange },
  data () {
    return {
      totalCommission: 0,
      panel: 0,
      loaded: false,
      loadingPdf: false,
      loadingArtistPdf: -1,
      imageUrl: process.env.VUE_APP_IMG_URL,
      artists: {},
      rawData: [],
      rawXeroData: [],
      summaryHeaders: [
        {
          text: 'Magnets',
          align: 'end',
          value: 'Magnets'
        },
        {
          text: 'Greeting Cards',
          align: 'end',
          value: 'Greeting Cards'
        },
        {
          text: 'Small Mounted Prints',
          align: 'end',
          value: 'Small Mounted Prints'
        },
        {
          text: 'Open Edition Giclees',
          align: 'end',
          value: 'Open Edition Giclee'
        },
        {
          text: 'Limited Edition Giclees',
          align: 'end',
          value: 'Limited Edition Giclee'
        },
        {
          text: 'Mugs',
          align: 'end',
          value: 'Mugs'
        }
      ],
      detailHeaders: [
        {
          text: 'Item Description',
          width: 10,
          value: 'itemDescription'
        },
        {
          text: 'Quantity Sold',
          width: 10,
          align: 'end',
          value: 'numItems'
        },
        {
          text: 'Commission (per Item)',
          width: 10,
          align: 'center',
          value: 'commissionDescription'
        },
        {
          text: 'Payment Due',
          width: 10,
          align: 'end',
          value: 'commission'
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    getCommission (dates) {
      console.log('dates',this.$store.getters['reports/dateRange'])
      this.dates = dates
      ReportApi.loadXeroLineItemsWithProduct({ dateRange: dates }).then((results) => {
        this.rawXeroData = results.data
      })
      ReportApi.loadArtistCommission({ dateRange: dates }).then((results) => {
        this.rawData = results.data
        this.totalCommission = this.rawData.reduce((accumulator, object) => {
          return accumulator + +object.commission?.toFixed(2)
        }, 0)
        this.artists = _.groupBy(results.data, 'artist')
        this.panel = 0
        this.loaded = true
      })
    },
    getArtistSummary (artist) {
      let total = 0
      for (const item of artist) {
        total += item.commission
      }
      return total
    },
    download (artist) {
      this.loadingPdf = true
      ReportApi.getCommissionPdf({ dateRange: this.dates }).then((results) => {
        this.loadingPdf = false
      })
    },
    downloadArtistCommission (artistCode, index) {
      this.loadingArtistPdf = index
      ReportApi.getCommissionPdf({ dateRange: this.dates, artistCode: artistCode }).then((results) => {
        this.loadingArtistPdf = -1
      })
    },
    downloadExcel () {
      const workbook = xlsx.utils.book_new()
      const worksheet = xlsx.utils.json_to_sheet(this.rawData)
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Commission')
      xlsx.writeFile(workbook, `ARTIST-COMMISSION-${this.dates[0]}-${this.dates[1]}).xlsx`)
    },
    downloadXeroExcel () {
      const headers = [
        'Item Code',
        'Description',
        'Quantity',
        'Unit Price For Commission',
        'Unit Price(ex)',
        'Net',
        'Contact',
        'Invoice Date',
        'Invoice Number'
      ]
      const workbook = xlsx.utils.book_new()
      const worksheet = xlsx.utils.json_to_sheet(this.rawXeroData, { header: headers })
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Commission')
      xlsx.writeFile(workbook, `ARTIST-COMMISSION-XERO-${this.dates[0]}-${this.dates[1]}).xlsx`)
    }
  },
  async created () {
  }
}
</script>
